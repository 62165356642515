<template>
    <psi-form-layout-section
        title="Company and Property"
        icon="mdi-home-city"
        hide-actions
        btn-label="Save"
        btn-icon="mdi-arrow-right-bold"
        :loading="loading"
    >
        <psi-form-section
            ref="section"
            v-if="properties.length > 0 && companies.length > 0"
            :value="setup"
            name="property-setup"
            label=""
            :fields="fields"
            color="secondary"
            @update:company="updateCompany"
            @update:property="updateProperty"
        ></psi-form-section>
    </psi-form-layout-section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "select-property",
    components: {},
    props: {
        property: {
            type: Object,
            required: true,
        },
        company: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            setup: {
                property_id: this.$_.get(this.property, "id", 0),
                company_id: this.$_.get(this.property, "company_id", 0),
            },
            fields: [
                {
                    type: "autocomplete",
                    name: "company",
                    key: "company_id",
                    label: "Company",
                    icon: this.$config("icons.company"),
                    "item-value": "id",
                    "item-text": "name",
                    items: [],
                    required: true,
                },
                {
                    type: "autocomplete",
                    name: "property",
                    key: "property_id",
                    label: "Property",
                    icon: this.$config("icons.property"),

                    "item-value": "id",
                    "item-text": "name",
                    items: [],
                    required: true,
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Companies", [
            "companies",
            "properties",
            "currentCompany",
            "currentProperty",
            "loading",
        ]),
    },
    watch: {
        companies: {
            handler(companies) {
                this.fields = this.fields.map((field) => {
                    if (field.name === "company") {
                        field.items = companies;
                    }
                    return field;
                });
            },
        },
        currentCompany: {
            handler(company) {
                this.fields = this.fields.map((field) => {
                    if (field.name === "property") {
                        field.items = this.$_.get(
                            company,
                            "properties",
                            []
                        ).sort((a, b) => a > b);
                    }
                    return field;
                });
                this.$emit("update:company", company);
            },
        },
        currentProperty: {
            handler(property) {
                if (this.$_.get(property, "company_id", "")) {
                    this.setup.company_id = property.company_id;
                    this.updateCompany(property.company_id);
                } else {
                    this.setup.property_id = 0;
                    this.$refs.section.resetValidation();
                }
                this.$emit("update:property", property);
            },
        },
        properties: {
            immediate: true,
            deep: true,
            handler(properties) {
                this.fields = this.fields.map((field) => {
                    if (field.name === "property") {
                        field.items = properties;
                    }
                    return field;
                });

                const property_id = parseInt(
                    localStorage.getItem("property_id")
                );

                if (property_id) {
                    const index = properties.findIndex(
                        (p) => p.id === property_id
                    );
                    if (index !== -1) {
                        this.updateProperty(property_id);
                    }
                }
            },
        },
    },
    created() {},
    mounted() {
        this.getCompanies();
        this.getProperties();
    },
    updated() {},
    detroyed() {},
    methods: {
        ...mapActions("Companies", [
            "getCompanies",
            "getProperties",
            "getCurrentCompany",
            "getCurrentProperty",
        ]),
        updateCompany(companyId) {
            this.getCurrentCompany(companyId);
            this.setup.company_id = companyId;
        },
        updateProperty(propertyId) {
            this.setup.property_id = propertyId;
            this.getCurrentProperty(propertyId);
            localStorage.setItem("property_id", propertyId);
        },
        validate() {
            return this.$refs.section.validate();
        },
    },
};
</script>

<style scoped>
</style>
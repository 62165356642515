var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-form-layout-section",
    {
      attrs: {
        title: "Application Source",
        icon: "mdi-import",
        "hide-actions": "",
        "btn-label": "Save",
        "btn-icon": "mdi-arrow-right-bold"
      }
    },
    [
      _c("psi-form-section", {
        ref: "section",
        attrs: {
          value: _vm.sourceData,
          name: "application-source",
          label: "",
          fields: _vm.fields,
          color: "secondary"
        },
        on: { "update:product": _vm.updateSource }
      }),
      _c("div", { staticStyle: { height: "52px" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-form-layout-section",
    {
      attrs: {
        title: "Applicant Files",
        icon: "mdi-file",
        "hide-actions": "",
        "btn-label": "Save",
        "btn-icon": "mdi-arrow-right-bold"
      }
    },
    [
      _c("test-toolbar", {
        on: {
          "fill-data": function($event) {
            return _vm.fillApplicantFiles($event)
          }
        }
      }),
      _vm.loading ? _c("psi-content-loader") : _vm._e(),
      _c(
        "v-tabs",
        {
          ref: "tabs",
          staticClass: "mb-1",
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _vm._l(_vm.applicants, function(applicant, index) {
            return [
              _c("v-tab", { key: index }, [_vm._v(_vm._s(applicant.name))])
            ]
          })
        ],
        2
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _vm._l(_vm.applicantsData, function(applicant, index) {
            return [
              _c(
                "v-tab-item",
                { key: index, attrs: { eager: "" } },
                [
                  _c("v-switch", {
                    staticClass: "ml-4 mb-2",
                    attrs: {
                      dense: "",
                      hint:
                        "Application is combined in a single file. You can specify page ranges to split the file after upload.",
                      "persistent-hint": "",
                      label: "Combined Application",
                      color: "primary",
                      "input-value": applicant.combined
                    },
                    on: {
                      change: function($event) {
                        return _vm.onCombined(applicant, $event)
                      }
                    }
                  }),
                  _vm.$_.get(applicant, "combined", false)
                    ? _c("application-combined", {
                        ref: "applicant-media",
                        refInFor: true,
                        attrs: { applicant: applicant },
                        on: { "update:applicant": _vm.updateApplicant }
                      })
                    : _c("applicant-media", {
                        ref: "applicant-media",
                        refInFor: true,
                        attrs: { loading: _vm.loading, applicant: applicant },
                        on: { "update:applicant": _vm.updateApplicant }
                      })
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
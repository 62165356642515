var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.sections, function(section, index) {
        return [
          _c(
            "v-card",
            { key: index, attrs: { disabled: _vm.loading } },
            [
              _c(
                "v-card-title",
                { staticClass: "text-subtitle-1 d-flex" },
                [
                  _c("span", [_vm._v(_vm._s(section.title))]),
                  _c("v-spacer"),
                  section.addFiles
                    ? _c(
                        "v-btn",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { small: "", color: "secondary" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.addFile(section.category)
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { small: "" } },
                            [_vm._v(_vm._s(section.addFilesIcon))]
                          ),
                          _vm._v(_vm._s(section.addFilesLabel))
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "my-0 py-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { wrap: "", dense: "" } },
                    [
                      _vm._l(section.files, function(ref$1, j) {
                        var ref = ref$1.ref
                        var label = ref$1.label
                        var category = ref$1.category
                        var media = ref$1.media
                        var required = ref$1.required
                        var uuid = ref$1.uuid
                        return [
                          _c(
                            "v-col",
                            {
                              key: index + "-" + j,
                              staticClass: "my-0 py-0",
                              attrs: { cols: "6" }
                            },
                            [
                              _c("psi-form-media-upload", {
                                key: uuid + "-" + Object.keys(media).length,
                                ref: ref,
                                refInFor: true,
                                attrs: {
                                  label: label,
                                  category: category,
                                  uuid: uuid,
                                  value: media,
                                  required: required
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.updateFiles(uuid, $event)
                                  },
                                  afterUpload: function($event) {
                                    return _vm.afterUpload($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
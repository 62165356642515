var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "start-application-top" } },
    [
      _c(
        "v-card",
        { staticClass: "mt-4" },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-stepper",
                {
                  model: {
                    value: _vm.step,
                    callback: function($$v) {
                      _vm.step = $$v
                    },
                    expression: "step"
                  }
                },
                [
                  _c(
                    "v-stepper-header",
                    [
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.step > 1, step: "1" } },
                        [_vm._v(" New Application ")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.step > 2, step: "2" } },
                        [_vm._v(" Upload Application ")]
                      ),
                      _c("v-divider"),
                      _c("v-stepper-step", { attrs: { step: "3" } }, [
                        _vm._v(" Confirm Application ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-items",
                    [
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-4 mb-2",
                                  attrs: { offset: "1", cols: "10" }
                                },
                                [
                                  _c("select-property", {
                                    ref: "section-company-and-property",
                                    attrs: {
                                      property: _vm.property,
                                      company: _vm.company
                                    },
                                    on: {
                                      "update:property": function($event) {
                                        _vm.property = $event
                                      },
                                      "update:company": function($event) {
                                        _vm.company = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { offset: "1", cols: "10" } },
                                [
                                  _c("select-product", {
                                    ref: "section-tenant-screening-product",
                                    attrs: {
                                      products: _vm.$_.get(
                                        _vm.property,
                                        "property_group.products",
                                        []
                                      ),
                                      product: _vm.product
                                    },
                                    on: {
                                      "update:product": function($event) {
                                        _vm.product = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { offset: "1", cols: "10" } },
                                [
                                  _c("setup-details", {
                                    ref: "section-rental-unit",
                                    attrs: { details: _vm.details },
                                    on: {
                                      "update:details": function($event) {
                                        _vm.details = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { offset: "1", cols: "10" } },
                                [
                                  _c("select-source", {
                                    ref: "section-application-source",
                                    attrs: {
                                      sources: _vm.sources,
                                      source: _vm.source
                                    },
                                    on: {
                                      "update:source": function($event) {
                                        _vm.source = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mb-2 py-4",
                                  attrs: { offset: "1", cols: "10" }
                                },
                                [
                                  _c("setup-application", {
                                    ref: "section-application-group",
                                    attrs: { applicants: _vm.applicants },
                                    on: {
                                      "update:applicants": function($event) {
                                        _vm.applicants = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-4 mb-2",
                                  attrs: { offset: "1", cols: "10" }
                                },
                                [
                                  _vm.step === 2
                                    ? _c("setup-applicant-files", {
                                        ref: "applicant-files",
                                        attrs: { applicants: _vm.applicants },
                                        on: {
                                          "update:applicants": function(
                                            $event
                                          ) {
                                            _vm.applicants = $event
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "3" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-4 mb-2",
                                  attrs: { offset: "1", cols: "10" }
                                },
                                [
                                  _c("application-uploaded", {
                                    on: {
                                      cancel: function($event) {
                                        return _vm.$emit("cancel")
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.step !== 3
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pl-16 mb-6",
                              attrs: { offset: "4" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.continueApplication()
                                    }
                                  }
                                },
                                [_vm._v(" Continue ")]
                              ),
                              _vm.step > 1
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { text: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.$emit("cancel")
                                        }
                                      }
                                    },
                                    [_vm._v(" Cancel ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.loading
                    ? _c("v-progress-linear", {
                        attrs: { indeterminate: "", color: "primary" }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: {
            bottom: "",
            timeout: 5000,
            "multi-line": "",
            color: "error darken-2",
            text: ""
          },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", text: "" },
                  on: {
                    click: function($event) {
                      _vm.snackbar = false
                    }
                  }
                },
                [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "ul",
            _vm._l(_vm.errors, function(error, index) {
              return _c("li", { key: index }, [
                _vm._v(" " + _vm._s(error) + " ")
              ])
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
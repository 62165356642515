<template>
    <psi-form-layout-section
        title="Applicant Files"
        icon="mdi-file"
        hide-actions
        btn-label="Save"
        btn-icon="mdi-arrow-right-bold"
    >
        <test-toolbar @fill-data="fillApplicantFiles($event)"></test-toolbar>
        <psi-content-loader v-if="loading"></psi-content-loader>

        <v-tabs class="mb-1" v-model="tab" ref="tabs">
            <template v-for="(applicant, index) in applicants">
                <v-tab :key="index">{{ applicant.name }}</v-tab>
            </template>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <template v-for="(applicant, index) in applicantsData">
                <v-tab-item :key="index" eager>
                    <v-switch
                        dense
                        class="ml-4 mb-2"
                        hint="Application is combined in a single file. You can specify page ranges to split the file after upload."
                        persistent-hint
                        label="Combined Application"
                        color="primary"
                        :input-value="applicant.combined"
                        @change="onCombined(applicant, $event)"
                    ></v-switch>
                    <application-combined
                        ref="applicant-media"
                        v-if="$_.get(applicant, 'combined', false)"
                        :applicant="applicant"
                        @update:applicant="updateApplicant"
                    ></application-combined>

                    <applicant-media
                        v-else
                        ref="applicant-media"
                        :loading="loading"
                        :applicant="applicant"
                        @update:applicant="updateApplicant"
                    ></applicant-media>
                </v-tab-item>
            </template>
        </v-tabs-items>
    </psi-form-layout-section>
</template>
<script>
import ApplicantMedia from "../Media/ApplicantMedia.vue";
import ApplicationCombined from "../Media/ApplicationCombined.vue";
import TestToolbar from "./TestToolbar";
export default {
    name: "setup-applicant-files",
    components: {
        ApplicantMedia,
        ApplicationCombined,
        TestToolbar,
    },
    props: {
        applicants: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            tab: 0,
            mode: process.env.NODE_ENV,
            loading: false,
            applicantsData: this.applicants,
        };
    },
    computed: {},
    watch: {
        applicants: {
            immediate: true,
            deep: true,
            handler(applicants) {
                this.applicantsData = applicants;
            },
        },
    },
    methods: {
        async fillApplicantFiles(required) {
            this.loading = true;
            try {
                const applicantMedia = this.$refs["applicant-media"];
                for (let i = 0; i < applicantMedia.length; i++) {
                    await applicantMedia[i].fillSections(required);
                }
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
            }
        },
        onCombined(applicant, combined) {
            let item = this.applicantsData.find(
                (item) => item.uuid === applicant.uuid
            );
            item.combined = combined;
            const index = this.applicantsData.findIndex(
                (item) => item.uuid === applicant.uuid
            );
            this.applicantsData.splice(index, 1, item);

            this.$emit("update:applicants", this.applicantsData);
        },
        updateApplicant(applicant) {
            const index = this.applicantsData.findIndex(
                (item) => item.uuid === applicant.uuid
            );
            this.applicantsData.splice(index, 1, applicant);
            this.$emit("update:applicants", this.applicantsData);
        },
        validate() {
            let validations = [];
            this.$refs["applicant-media"].forEach((media) => {
                validations.push({
                    name: media.applicantData.name,
                    error: `Error in ${media.applicantData.type} ${media.applicantData.name}`,
                    valid: media.validate(),
                });
            });
            return validations;
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-form-layout-section",
    {
      attrs: {
        title: "Company and Property",
        icon: "mdi-home-city",
        "hide-actions": "",
        "btn-label": "Save",
        "btn-icon": "mdi-arrow-right-bold",
        loading: _vm.loading
      }
    },
    [
      _vm.properties.length > 0 && _vm.companies.length > 0
        ? _c("psi-form-section", {
            ref: "section",
            attrs: {
              value: _vm.setup,
              name: "property-setup",
              label: "",
              fields: _vm.fields,
              color: "secondary"
            },
            on: {
              "update:company": _vm.updateCompany,
              "update:property": _vm.updateProperty
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
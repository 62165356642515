<template>
    <psi-form-layout-section
        title="Rental Unit"
        :icon="$config('icons.unit')"
        hide-actions
        btn-label="Save"
        btn-icon="mdi-arrow-right-bold"
    >
        <v-btn
            absolute
            fab
            top
            small
            right
            color="warning darken-2"
            @click.stop="fill"
            v-if="mode !== 'production'"
            ><v-icon>mdi-form-select</v-icon></v-btn
        >
        <psi-form-section
            :value="detailsData"
            ref="form"
            name="setup-details"
            label=""
            :fields="fields"
            color="secondary"
            @input="updateDetails"
        ></psi-form-section>
    </psi-form-layout-section>
</template>
<script>
export default {
    name: "setup-details",
    components: {},
    props: {
        details: {
            type: Object,
            required: false,
            default: () => {
                return {
                    rent: 0,
                    unit: "",
                };
            },
        },
    },
    data() {
        return {
            mode: process.env.NODE_ENV,
            detailsData: Object.assign(
                {
                    rent: 0,
                    unit: "",
                },
                this.details
            ),
            fields: [
                {
                    id: this.$uuid.v4(),
                    type: "text",
                    name: "unit",
                    key: "unit",
                    label: "Unit",
                    icon: this.$config("icons.unit"),
                    required: true,
                },
                {
                    id: this.$uuid.v4(),
                    type: "currency",
                    name: "rent",
                    key: "rent",
                    label: "Rent",
                    icon: this.$config("icons.rent"),
                    required: true,
                },
            ],
        };
    },
    methods: {
        updateDetails(details) {
            this.detailsData = details;
            this.$emit("update:details", this.detailsData);
        },
        validate() {
            return this.$refs.form.validate();
        },
        fill() {
            this.updateDetails({
                rent: Math.floor(Math.random() * 1000 + 1),
                unit:
                    "Unit " +
                    Math.random().toString(36).substring(10).toUpperCase(),
            });
        },
    },
    mounted() {},
};
</script>

<style scoped>
</style>
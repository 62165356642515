<template>
    <v-speed-dial
        class="mt-n4"
        v-model="fab"
        absolute
        top
        right
        direction="bottom"
        transition="slide-y-reverse-transition"
    >
        <template v-slot:activator>
            <v-btn v-model="fab" color="warning darken-2" dark fab>
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-icon v-else> mdi-text-account </v-icon>
            </v-btn>
        </template>
        <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    fab
                    dark
                    color="error darken-1"
                    @click="$emit('fill-data', true)"
                >
                    <v-icon>mdi-file-alert-outline</v-icon>
                </v-btn>
            </template>
            <span>Required Files Only</span>
        </v-tooltip>
        <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    fab
                    dark
                    color="primary"
                    @click="$emit('fill-data', false)"
                >
                    <v-icon>mdi-file-document-multiple-outline</v-icon>
                </v-btn>
            </template>
            <span>All Files (slower)</span>
        </v-tooltip>
    </v-speed-dial>
</template>
<script>
export default {
    name: "test-toolbar",
    components: {},
    props: {},
    data() {
        return {
            fab: false,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-form-layout-section",
    {
      attrs: {
        title: "Application Group",
        icon: "mdi-account-multiple",
        "hide-actions": "",
        "btn-label": "Save",
        "btn-icon": "mdi-arrow-right-bold"
      }
    },
    [
      _vm.mode !== "production"
        ? _c(
            "v-btn",
            {
              attrs: {
                absolute: "",
                fab: "",
                top: "",
                small: "",
                right: "",
                color: "warning darken-2"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.fill($event)
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-form-select")])],
            1
          )
        : _vm._e(),
      _vm._l(_vm.configurations, function(config, index) {
        return [
          _c(
            "v-row",
            { key: index },
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("psi-form-section", {
                    attrs: {
                      value: config.data,
                      name: "application-setup-" + config.type,
                      label: "",
                      fields: config.fields,
                      color: "secondary"
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateConfiguration($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "9" } },
                [
                  _c("psi-form-section", {
                    ref: "applicants",
                    refInFor: true,
                    staticClass: "mt-3",
                    attrs: {
                      value: config.persons,
                      label: "",
                      "two-cols": "",
                      name: "application-" + config.type,
                      fields: _vm.typedPersonFields[index],
                      color: "secondary"
                    },
                    on: {
                      input: function($event) {
                        return _vm.updatePersons(config.type, $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          index != _vm.configurations.length - 1
            ? _c("v-divider", {
                key: "divider-" + index,
                staticClass: "mt-2 mb-4"
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
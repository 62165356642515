var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-form-layout-section",
    {
      attrs: {
        title: "Tenant Screening Product",
        icon: "mdi-tag",
        "hide-actions": "",
        "btn-label": "Save",
        "btn-icon": "mdi-arrow-right-bold",
        loading: _vm.loading
      }
    },
    [
      _c("psi-form-section", {
        ref: "form",
        attrs: {
          value: _vm.productData,
          name: "product-setup",
          label: "",
          fields: _vm.fields,
          color: "secondary"
        },
        on: { "update:product": _vm.updateProduct }
      }),
      _c(
        "div",
        { staticStyle: { "min-height": "80px" } },
        [
          Object.keys(_vm.product).length > 0
            ? _c("h4", [_vm._v("Product Details")])
            : _vm._e(),
          _c(
            "v-slide-y-reverse-transition",
            [
              Object.keys(_vm.product).length > 0
                ? _c("psi-detail-card", {
                    attrs: { "hide-heading": "", items: _vm.items }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
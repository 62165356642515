import { render, staticRenderFns } from "./SetupApplicantFiles.vue?vue&type=template&id=7b7af38e&scoped=true&"
import script from "./SetupApplicantFiles.vue?vue&type=script&lang=js&"
export * from "./SetupApplicantFiles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b7af38e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VSwitch,VTab,VTabItem,VTabs,VTabsItems})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b7af38e')) {
      api.createRecord('7b7af38e', component.options)
    } else {
      api.reload('7b7af38e', component.options)
    }
    module.hot.accept("./SetupApplicantFiles.vue?vue&type=template&id=7b7af38e&scoped=true&", function () {
      api.rerender('7b7af38e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/uploads/components/Start/SetupApplicantFiles.vue"
export default component.exports
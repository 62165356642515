var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "psi-form-layout-section",
    {
      attrs: {
        title: "Rental Unit",
        icon: _vm.$config("icons.unit"),
        "hide-actions": "",
        "btn-label": "Save",
        "btn-icon": "mdi-arrow-right-bold"
      }
    },
    [
      _vm.mode !== "production"
        ? _c(
            "v-btn",
            {
              attrs: {
                absolute: "",
                fab: "",
                top: "",
                small: "",
                right: "",
                color: "warning darken-2"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.fill($event)
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-form-select")])],
            1
          )
        : _vm._e(),
      _c("psi-form-section", {
        ref: "form",
        attrs: {
          value: _vm.detailsData,
          name: "setup-details",
          label: "",
          fields: _vm.fields,
          color: "secondary"
        },
        on: { input: _vm.updateDetails }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
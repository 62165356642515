<template>
    <div>
        <template v-for="(section, index) in sections">
            <v-card :key="index" :disabled="loading">
                <v-card-title class="text-subtitle-1 d-flex">
                    <span>{{ section.title }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click.stop="addFile(section.category)"
                        style="width: 160px"
                        small
                        color="secondary"
                        v-if="section.addFiles"
                        ><v-icon small class="mr-2">{{
                            section.addFilesIcon
                        }}</v-icon
                        >{{ section.addFilesLabel }}</v-btn
                    >
                </v-card-title>
                <v-card-text class="my-0 py-0">
                    <v-row wrap dense>
                        <template
                            v-for="(
                                { ref, label, category, media, required, uuid },
                                j
                            ) in section.files"
                        >
                            <v-col
                                :key="`${index}-${j}`"
                                cols="6"
                                class="my-0 py-0"
                            >
                                <psi-form-media-upload
                                    :ref="ref"
                                    :key="`${uuid}-${
                                        Object.keys(media).length
                                    }`"
                                    :label="label"
                                    :category="category"
                                    :uuid="uuid"
                                    :value="media"
                                    :required="required"
                                    @input="updateFiles(uuid, $event)"
                                    @afterUpload="afterUpload($event)"
                                ></psi-form-media-upload>
                            </v-col>
                        </template>
                    </v-row>
                </v-card-text>
            </v-card>
        </template>
    </div>
</template>
<script>
import uuid from "uuid";

import api from "@components/uploads/api";

export default {
    name: "applicant-media",
    components: {
        PsiFormMediaUpload: () => import("./PsiFormMediaUpload"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            applicantData: Object.assign({}, this.applicant),
            sections: [
                {
                    title: "Application and Authorization",
                    category: "Application",
                    addFiles: false,
                    addFilesLabel: "",
                    addFilesIcon: "",
                    files: [
                        {
                            ref: "application",
                            label: "Application",
                            required: true,
                            category: "Application",
                            media: {},
                            uuid: uuid.v4(),
                        },
                        {
                            ref: "authorization",
                            label: "Authorization",
                            required: true,
                            category: "Authorization",
                            media: {},
                            uuid: uuid.v4(),
                        },
                    ],
                },
                {
                    title: "Income Verification",
                    category: "Income",
                    addFiles: true,
                    addFilesLabel: "Add Income File",
                    addFilesIcon: "mdi-file-table-outline",
                    files: [
                        {
                            ref: "income",
                            label: "Income Verification",
                            required: false,
                            category: "Income",
                            media: {},
                            uuid: uuid.v4(),
                        },
                        {
                            ref: "income",
                            label: "Income Verification",
                            required: false,
                            category: "Income",
                            media: {},
                            uuid: uuid.v4(),
                        },
                    ],
                },
                {
                    title: "Identification Verification",
                    category: "Identification",
                    addFiles: true,
                    addFilesLabel: "Add ID File",
                    addFilesIcon: "mdi-file-account-outline",
                    files: [
                        {
                            ref: "identification",
                            label: "Identification Verification",
                            required: false,
                            category: "Identification",
                            media: {},
                            uuid: uuid.v4(),
                        },
                    ],
                },
                {
                    title: "Other Files",
                    category: "Other",
                    addFiles: true,
                    addFilesLabel: "Add Other File",
                    addFilesIcon: "mdi-file-check-outline",
                    files: [
                        {
                            ref: "other",
                            label: "Other File",
                            required: false,
                            category: "Other",
                            media: {},
                            uuid: uuid.v4(),
                        },
                        {
                            ref: "other",
                            label: "Other File",
                            required: false,
                            category: "Other",
                            media: {},
                            uuid: uuid.v4(),
                        },
                    ],
                },
            ],
        };
    },
    watch: {
        applicant: {
            immediate: true,
            deep: true,
            handler(applicant) {
                this.applicantData = Object.assign({}, applicant);
            },
        },
    },
    methods: {
        async fillSections(required) {
            try {
                let sections = await api.fill(this.sections, required);
                this.sections = sections;
                this.associate();
            } catch (error) {
                throw new Error("Error in filling section files " + error);
            }
        },
        validate() {
            let validations = [];
            this.sections.forEach((section) => {
                section.files.forEach((file) => {
                    const files = this.$refs[file.ref];
                    files.forEach((f) => {
                        validations.push({
                            "file-category": file.ref,
                            valid: f.validate(),
                        });
                    });
                });
            });
            return validations.every((item) => item.valid);
        },

        associate() {
            let media = {};
            this.sections.forEach((section) => {
                section.files.forEach((file) => {
                    if (Object.keys(file.media).length > 0) {
                        media = Object.assign(media, file.media);
                    }
                });
            });
            this.applicantData.media = Object.assign({}, media);
            this.$emit("update:applicant", this.applicantData);
        },
        updateFiles(uuid, media) {
            const sectionIndex = this.sections.findIndex((section) => {
                return (
                    section.files.findIndex((file) => file.uuid === uuid) !== -1
                );
            });
            if (sectionIndex !== -1) {
                let section = this.sections[sectionIndex];
                const fileIndex = section.files.findIndex(
                    (file) => file.uuid === uuid
                );
                let file = section.files[fileIndex];
                file.media = media;
                this.sections[sectionIndex].files.splice(
                    fileIndex,
                    1,
                    Object.assign({}, file)
                );
                this.sections.splice(
                    sectionIndex,
                    1,
                    Object.assign({}, section)
                );
            }
            this.associate();
        },
        addFile(category) {
            let section = this.sections.find(
                (section) => section.category === category
            );
            const index = this.sections.findIndex(
                (section) => section.category === category
            );
            section.files.push(
                Object.assign(section.files[0], {
                    uuid: this.$uuid.v4(),
                    media: {},
                })
            );
            this.sections.splice(index, 1, Object.assign({}, section));
        },
        afterUpload(event) {
            console.log(event);
        },
    },
};
</script>

<style scoped>
</style>
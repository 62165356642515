var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-speed-dial",
    {
      staticClass: "mt-n4",
      attrs: {
        absolute: "",
        top: "",
        right: "",
        direction: "bottom",
        transition: "slide-y-reverse-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  attrs: { color: "warning darken-2", dark: "", fab: "" },
                  model: {
                    value: _vm.fab,
                    callback: function($$v) {
                      _vm.fab = $$v
                    },
                    expression: "fab"
                  }
                },
                [
                  _vm.fab
                    ? _c("v-icon", [_vm._v(" mdi-close ")])
                    : _c("v-icon", [_vm._v(" mdi-text-account ")])
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.fab,
        callback: function($$v) {
          _vm.fab = $$v
        },
        expression: "fab"
      }
    },
    [
      _c(
        "v-tooltip",
        {
          attrs: { left: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { fab: "", dark: "", color: "error darken-1" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("fill-data", true)
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-file-alert-outline")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Required Files Only")])]
      ),
      _c(
        "v-tooltip",
        {
          attrs: { left: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { fab: "", dark: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("fill-data", false)
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", [
                        _vm._v("mdi-file-document-multiple-outline")
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("All Files (slower)")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div id="page-application-start">
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="breadcrumbs"
        ></psi-page-title>

        <start-stepper
            @cancel="forceRerender"
            :key="componentKey"
        ></start-stepper>
    </div>
</template>

<script>
import StartStepper from "../components/Start/StartStepper";

export default {
    name: "application-start",
    components: {
        StartStepper,
    },
    props: {},
    data: () => ({
        componentKey: 1,
        page: {
            title: "Application Start",
            icon: "mdi-clipboard-plus-outline",
        },
        breadcrumbs: [
            {
                text: "Home",
                disabled: false,
                to: "#",
            },
            {
                text: "Uploads",
                disabled: false,
                to: "#",
            },
            {
                text: "Application Start",
                disabled: true,
                to: "#",
            },
        ],
    }),
    watch: {},
    computed: {},
    methods: {
        scrollTop() {
            setTimeout(() => {
                this.$vuetify.goTo(`#page-application-start`, {
                    easing: "easeInOutCubic",
                    offset: 15,
                    duration: 450,
                });
            }, 350);
        },
        forceRerender() {
            // changing the key forces Vue to rerender
            this.componentKey = this.componentKey + 1;
            this.scrollTop();
        },
    },
};
</script>
<style>
.v-stepper__wrapper {
    transition: none !important;
}
</style>
export default {
    methods: {
        applicantDisplay(type) {
            let icon = "";
            let color = "";
            let textClass = "";
            switch (type) {
                case "Applicant":
                    icon = "mdi-account-box";
                    color = "primary";
                    textClass = "primary--text";
                    break;
                case "Cosigner":
                    icon = "mdi-account-cash";
                    color = "primary";
                    textClass = "primary--text";
                    break;
                case "Non Applicant":
                    icon = "mdi-account-cancel";
                    color = "warning darken-2";
                    textClass = "warning--text text--darken-2";
                    break;
            }
            return { icon, color, textClass };
        },
        buildApplyUrl(params) {
            return `${process.env.VUE_APP_APPLY_HOST}/applications/applicant/edit?applicant_id=${params.applicant_id}&email=${params.email}&expiration=${params.expiration}&signature=${params.signature}`;
        },
    },
};

<template>
    <psi-form-layout-section
        title="Application Group"
        icon="mdi-account-multiple"
        hide-actions
        btn-label="Save"
        btn-icon="mdi-arrow-right-bold"
    >
        <v-btn
            absolute
            fab
            top
            small
            right
            color="warning darken-2"
            @click.stop="fill"
            v-if="mode !== 'production'"
            ><v-icon>mdi-form-select</v-icon></v-btn
        >
        <template v-for="(config, index) in configurations">
            <v-row :key="index">
                <v-col cols="3">
                    <psi-form-section
                        :value="config.data"
                        :name="`application-setup-${config.type}`"
                        label=""
                        :fields="config.fields"
                        color="secondary"
                        @input="updateConfiguration($event)"
                    ></psi-form-section>
                </v-col>
                <v-col cols="9">
                    <psi-form-section
                        class="mt-3"
                        :value="config.persons"
                        label=""
                        ref="applicants"
                        two-cols
                        :name="`application-${config.type}`"
                        :fields="typedPersonFields[index]"
                        color="secondary"
                        @input="updatePersons(config.type, $event)"
                    >
                    </psi-form-section>
                </v-col>
            </v-row>
            <v-divider
                :key="`divider-${index}`"
                v-if="index != configurations.length - 1"
                class="mt-2 mb-4"
            ></v-divider>
        </template>
    </psi-form-layout-section>
</template>
<script>
const DEFAULT_APPLICANTS_COUNT = 2;
const DEFAULT_COSIGNERS_COUNT = 0;
const DEFAULT_NONAPPLICANTS_COUNT = 0;

Array.prototype.sample = function () {
    return this[Math.floor(Math.random() * this.length)];
};
import names from "./Data/names.json";

export default {
    name: "setup-application",
    components: {},
    props: {
        applicants: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            mode: process.env.NODE_ENV,
            configurations: [
                {
                    type: "Applicant",
                    key: "applicants",
                    count: DEFAULT_APPLICANTS_COUNT,
                    fields: this.configurationFields("applicants", "Applicant"),
                    data: {
                        applicants: DEFAULT_APPLICANTS_COUNT,
                    },
                    persons: {},
                },
                {
                    type: "Cosigner",
                    key: "cosigners",
                    count: DEFAULT_COSIGNERS_COUNT,
                    fields: this.configurationFields("cosigners", "Cosigner"),
                    data: {
                        cosigners: DEFAULT_COSIGNERS_COUNT,
                    },
                    persons: {},
                },
                {
                    type: "Non Applicant",
                    key: "nonapplicants",
                    count: DEFAULT_NONAPPLICANTS_COUNT,
                    fields: this.configurationFields(
                        "nonapplicants",
                        "Non Applicant"
                    ),
                    data: {
                        nonapplicants: DEFAULT_NONAPPLICANTS_COUNT,
                    },
                    persons: {},
                },
            ],
            personFields: [
                {
                    type: "text",
                    name: "first_name",
                    key: "first_name",
                    label: "First Name",
                    icon: this.$config("icons.name"),
                    required: true,
                },
                {
                    type: "text",
                    name: "last_name",
                    key: "last_name",
                    label: "Last Name",
                    icon: this.$config("icons.name"),
                    required: true,
                },
            ],
        };
    },
    computed: {
        typedPersonFields: {
            immediate: true,
            get() {
                return this.configurations.map((config) => {
                    return this.groupedFields(
                        this.numbered(
                            this.personFields,
                            config.type,
                            config.count
                        )
                    );
                });
            },
        },
    },
    watch: {},
    mounted() {
        this.getApplicants();
    },

    methods: {
        firstName() {
            return names.first.sample().toUpperCase();
        },
        lastName() {
            return names.last.sample().toUpperCase();
        },
        fakePersons(count) {
            let persons = {};
            for (let i = 1; i <= count; i++) {
                (persons[`first_name_${i}`] = this.firstName()),
                    (persons[`last_name_${i}`] = this.lastName());
            }
            return persons;
        },
        fill() {
            this.configurations = this.configurations.map((item) => {
                item.persons = this.fakePersons(item.count);
                return item;
            });
            this.getApplicants();
        },
        validate() {
            return this.$refs.applicants
                .map((applicant) => {
                    return applicant.validate();
                })
                .every((item) => item);
        },
        // Configuration fields by applicant type
        configurationFields(type, label) {
            return [
                {
                    type: "select",
                    name: "",
                    key: "",
                    label: "",
                    items: [],
                    "item-value": "count",
                    "item-text": "count",
                    icon: "",
                    required: false,
                },
            ].map((field) => {
                field.name = type;
                field.key = type;
                field.label = label;
                field.items = this.counts();
                field.icon = this.$config(`icons.${type}`);
                return Object.assign({}, field);
            });
        },
        // Range of count selection to setup counts by applicant type
        counts() {
            let data = [];
            for (let i = 0; i < 10; i++) {
                data.push({
                    count: i,
                });
            }
            return data;
        },
        updatePersons(type, data) {
            let config = this.configurations.find(
                (config) => config.type === type
            );
            let index = this.configurations.findIndex(
                (config) => config.type === type
            );
            config.persons = Object.assign(config.persons, data);
            this.configurations.splice(index, 1, config);
            this.getApplicants();
        },
        dataDetails(data) {
            let key = Object.keys(data).shift();

            let index = parseInt(key.split("_").pop()) - 1;
            let field = key.split("_").shift();
            let value = data[key];

            return { index, field, value };
        },
        updateConfiguration(data) {
            const key = Object.keys(data).shift();

            let config = this.configurations.find(
                (config) => config.key === key
            );
            config.count = data[key];
            this.configurations.splice(
                this.configurations.findIndex(
                    (config) => config.key === Object.keys(data).shift()
                ),
                1,
                Object.assign({}, config)
            );
            this.getApplicants();
        },
        groupedFields(fields) {
            // create a group of 2 column meta fields
            let fieldGroup = [];
            for (let i = 0; i < fields.length; i += 2) {
                let items = [];
                items.push(fields[i]);
                if (i + 1 < fields.length) {
                    items.push(fields[i + 1]);
                }
                fieldGroup.push(items);
            }
            return fieldGroup;
        },
        numbered(fields, label, count) {
            let numberedFields = [];
            for (let i = 0; i < count; i++) {
                fields.forEach((field) => {
                    let dataField = Object.assign({}, field);
                    dataField.name += `_${i + 1}`;
                    dataField.key += `_${i + 1}`;
                    dataField.label = `${field.label} (${i + 1})`;
                    numberedFields.push(Object.assign({}, dataField));
                });
            }
            return numberedFields;
        },
        getApplicants() {
            let applicants = [];
            this.configurations.forEach((config) => {
                applicants = applicants.concat(this.getTypedApplicants(config));
            });
            this.$emit("update:applicants", applicants);
        },
        getTypedApplicants(config) {
            // We'll assume we don't have any applicant names and just pull from configuration count
            let applicants = this.initializeApplicants(config);
            const persons = this.parsePersons(config.count, config.persons);

            return applicants.map((applicant, index) => {
                applicant = Object.assign(applicant, persons[index]);
                return applicant;
            });
        },

        parsePersons(count, persons) {
            let result = [];
            for (let i = 0; i < count; i++) {
                result.push({});
            }

            Object.keys(persons).forEach((field) => {
                const index = parseInt(field.split("_").pop()) - 1;
                const key =
                    field.split("_").shift() + "_" + field.split("_")[1];
                result[index] = Object.assign(result[index], {
                    [key]: persons[field],
                });
            });
            return result.map((item) => {
                const name = (
                    this.$_.get(item, "first_name", "") +
                    " " +
                    this.$_.get(item, "last_name", "")
                ).trim();
                if (name) {
                    item.name = name;
                }
                return item;
            });
        },
        initializeApplicants(config) {
            let applicants = [];
            for (let i = 0; i < config.count; i++) {
                applicants.push({
                    type: config.type,
                    uuid: this.$uuid.v4(),
                    name: `${config.type} ${i + 1}`,
                    first_name: "N/A",
                    last_name: "N/A",
                });
            }
            return applicants;
        },
    },
};
</script>

<style scoped>
</style>
<template>
    <psi-form-layout-section
        title="Tenant Screening Product"
        icon="mdi-tag"
        hide-actions
        btn-label="Save"
        btn-icon="mdi-arrow-right-bold"
        :loading="loading"
    >
        <psi-form-section
            :value="productData"
            ref="form"
            name="product-setup"
            label=""
            :fields="fields"
            color="secondary"
            @update:product="updateProduct"
        ></psi-form-section>
        <div style="min-height: 80px">
            <h4 v-if="Object.keys(product).length > 0">Product Details</h4>
            <v-slide-y-reverse-transition>
                <psi-detail-card
                    hide-heading
                    :items="items"
                    v-if="Object.keys(product).length > 0"
                ></psi-detail-card>
            </v-slide-y-reverse-transition>
        </div>
    </psi-form-layout-section>
</template>
<script>
export default {
    name: "select-product",
    components: {},
    props: {
        products: {
            type: Array,
            required: true,
        },
        product: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            productData: this.product,
            loading: false,
            items: [
                {
                    label: "Name",
                    key: "name",
                    icon: this.$config("icons.product"),
                    type: "text",
                    text: "",
                },
                {
                    label: "Price",
                    key: "price",
                    icon: this.$config("icons.price"),
                    type: "text",
                    format: "currency",
                    text: "",
                },
                {
                    label: "Instant",
                    key: "instant",
                    type: "text",
                    icon: this.$config("icons.instant"),
                    format: "boolean",
                    text: "",
                },
                {
                    label: "Services",
                    key: "services",
                    field: "name",
                    type: "text",
                    icon: this.$config("icons.services"),
                    format: "array",
                    text: "",
                },
            ],
            fields: [
                {
                    type: "select",
                    name: "product",
                    key: "id",
                    label: "Product",
                    icon: this.$config("icons.product"),
                    "item-value": "id",
                    "item-text": "name",
                    items: this.products,
                    disabled: this.products.length === 0,
                    required: true,
                },
            ],
        };
    },
    computed: {},
    watch: {
        products(products) {
            this.loading = true;
            this.fields.map((item) => {
                item.disabled = products.length === 0;
                item.items = products;
                return item;
            });
            const product = products.find((item) => item.pivot.is_default);
            if (product) {
                this.updateProduct(product.id);
            }
            this.loading = false;
        },
        product(value) {
            if (Object.keys(value).length > 1) {
                this.productData = value;
                this.fillItems(value);
            } else {
                // Product is empty
                this.$refs.form.resetValidation();
            }
        },
    },
    methods: {
        validate() {
            return this.$refs.form.validate();
        },
        updateProduct(productId) {
            this.productData = this.products.find(
                (product) => product.id === productId
            );
            this.fillItems(this.productData);
            this.$emit("update:product", this.productData);
        },
        fillItems(product) {
            this.items = this.items.map((item) => {
                item.text = this.$_.get(product, item.key);
                if (this.$_.get(item, "format", "text") === "currency") {
                    item.text = this.$dollarFormat(item.text);
                }
                if (this.$_.get(item, "format", "text") === "boolean") {
                    item.text = item.text ? "Yes" : "No";
                }
                if (this.$_.get(item, "format", "text") === "array") {
                    item.text = item.text
                        .map((elem) => {
                            return elem[item.field];
                        })
                        .join("<br />");
                }
                return item;
            });
        },
    },
};
</script>

<style scoped>
</style>
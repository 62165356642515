<template>
    <div id="start-application-top">
        <v-card class="mt-4">
            <v-card-text>
                <v-stepper v-model="step">
                    <v-stepper-header>
                        <v-stepper-step :complete="step > 1" step="1">
                            New Application
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step :complete="step > 2" step="2">
                            Upload Application
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="3">
                            Confirm Application
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-row>
                                <v-col offset="1" cols="10" class="py-4 mb-2">
                                    <!-- Prefix ref with setup- so it's included in validation -->
                                    <select-property
                                        ref="section-company-and-property"
                                        :property.sync="property"
                                        :company.sync="company"
                                    ></select-property>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col offset="1" cols="10">
                                    <!-- Prefix ref with setup- so it's included in validation -->
                                    <select-product
                                        ref="section-tenant-screening-product"
                                        :products="
                                            $_.get(
                                                property,
                                                'property_group.products',
                                                []
                                            )
                                        "
                                        :product.sync="product"
                                    ></select-product>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col offset="1" cols="10">
                                    <!-- Prefix ref with setup- so it's included in validation -->
                                    <setup-details
                                        ref="section-rental-unit"
                                        :details.sync="details"
                                    ></setup-details>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col offset="1" cols="10">
                                    <!-- Prefix ref with setup- so it's included in validation -->
                                    <select-source
                                        ref="section-application-source"
                                        :sources="sources"
                                        :source.sync="source"
                                    ></select-source>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col offset="1" cols="10" class="mb-2 py-4">
                                    <!-- Prefix ref with setup- so it's included in validation -->
                                    <setup-application
                                        ref="section-application-group"
                                        :applicants.sync="applicants"
                                    ></setup-application>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <v-row>
                                <v-col offset="1" cols="10" class="py-4 mb-2">
                                    <!-- Don't setup a ref with section prefix here -->
                                    <setup-applicant-files
                                        v-if="step === 2"
                                        ref="applicant-files"
                                        :applicants.sync="applicants"
                                    ></setup-applicant-files>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <v-row>
                                <v-col offset="1" cols="10" class="py-4 mb-2">
                                    <!-- Don't setup a ref with section prefix here -->
                                    <application-uploaded
                                        @cancel="$emit('cancel')"
                                    ></application-uploaded>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                    </v-stepper-items>
                    <v-row v-if="step !== 3">
                        <v-col offset="4" class="pl-16 mb-6">
                            <v-btn
                                color="primary"
                                @click.stop="continueApplication()"
                                :loading="loading"
                            >
                                Continue
                            </v-btn>

                            <v-btn
                                text
                                class="ml-2"
                                v-if="step > 1"
                                @click.stop="$emit('cancel')"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-progress-linear
                        indeterminate
                        color="primary"
                        v-if="loading"
                    ></v-progress-linear>
                </v-stepper>
            </v-card-text>
        </v-card>
        <v-snackbar
            bottom
            :timeout="5000"
            v-model="snackbar"
            multi-line
            color="error darken-2"
            text
        >
            <div class="d-flex justify-end">
                <v-btn small text @click="snackbar = false"
                    ><v-icon small>mdi-close</v-icon></v-btn
                >
            </div>

            <ul>
                <li v-for="(error, index) in errors" :key="index">
                    {{ error }}
                </li>
            </ul>
        </v-snackbar>
    </div>
</template>

<script>
import SelectProperty from "./SelectProperty.vue";
import SelectProduct from "./SelectProduct.vue";
import SetupDetails from "./SetupDetails.vue";
import SetupApplication from "./SetupApplication.vue";
import SetupApplicantFiles from "./SetupApplicantFiles";
import SelectSource from "./SelectSource.vue";
import ApplicationUploaded from "../Complete/ApplicationUploaded.vue";

import { mapActions, mapGetters } from "vuex";
export default {
    name: "application-start",
    components: {
        SelectProduct,
        SelectProperty,
        SetupDetails,
        SetupApplication,
        SetupApplicantFiles,
        SelectSource,
        ApplicationUploaded,
    },
    props: {},
    data: () => ({
        step: 1,

        // Pull property and company from select property and add to application meta
        property: {},
        company: {},
        // Pull product from select product and add to application meta
        product: {},
        // Pull source from select source and add to application
        source: "",
        sources: [
            {
                name: "Appfolio",
            },
            {
                name: "Tenant Tech",
            },
            {
                name: "Other",
            },
        ],

        // Pull rent,unit from setup details and add to application meta
        details: {},
        // Placeholder for application, we'll populate from each section
        application: {
            meta: {
                applicants: [],
            },
            id: 0,
            source: "",
        },
        applicants: [],
        snackbar: false,
        errors: [],
    }),
    watch: {
        property() {
            this.application.meta.property = {
                id: this.property.id,
                name: this.property.name,
            };
            this.source = this.property.property_group.default_source;
        },
        company() {
            this.application.meta.company = {
                id: this.company.id,
                name: this.company.name,
            };
        },
        product() {
            this.application.meta.product = {
                id: this.product.id,
                name: this.product.name,
                price: this.product.price,
            };
        },
        details: {
            deep: true,
            immediate: true,
            handler(details) {
                this.application.meta.rent = details.rent;
                this.application.meta.unit = details.unit;
            },
        },
        source() {
            this.application.source = this.source;
        },
        currentApplication(application) {
            if (
                Object.keys(application).length > 0 &&
                this.$_.get(application, "id", 0) > 0
            ) {
                // continue since we have an application
                this.step = this.step + 1;
                this.scrollTop();
            }
        },
        currentApplicants(applicants) {
            // We wait here for the application to have completed order creation and online application submission
            if (applicants !== null && Array.isArray(applicants)) {
                if (applicants.length > 0 && this.hasOrders(applicants)) {
                    // TODO: CHECK FOR LOGGED IN USER , IF STAFF OR PROPERTY MANAGER

                    // this.$router.push({ name: "Uploads Applications" });
                    this.step = this.step + 1;
                    this.scrollTop();
                }
            }
        },
    },
    computed: {
        ...mapGetters("Uploads", [
            "currentApplication",
            "currentApplicants",
            "loading",
        ]),
    },
    methods: {
        ...mapActions("Uploads", [
            "storeApplication",
            "storeApplicants",
            "resetApplication",
        ]),
        hasOrders(applicants) {
            return applicants.every((item) => item.order !== null);
        },
        scrollTop() {
            setTimeout(() => {
                this.$vuetify.goTo("#start-application-top", {
                    easing: "easeInOutCubic",
                    offset: 15,
                    duration: 450,
                });
            }, 350);
        },
        handleApplicationSubmit() {
            this.errors = [];
            Object.keys(this.$refs).forEach((key) => {
                if (key.split("-").shift() === "section") {
                    const valid = this.$refs[key].validate();
                    if (!valid) {
                        this.errors.push(
                            `${this.$_.titleCase(
                                key.split("-").join(" ")
                            )} Contains Errors`
                        );
                    }
                }
            });
            this.snackbar = this.errors.length > 0;
            if (this.errors.length === 0) {
                this.application.meta.applicants = Object.assign(
                    {},
                    this.applicants
                );
                this.storeApplication(this.application);
            }
        },
        handleApplicantsSubmit() {
            this.errors = [];
            const validations = this.$refs["applicant-files"].validate();
            if (!validations.every((item) => item.valid)) {
                validations.forEach((validation) => {
                    if (!validation.valid) {
                        this.errors.push(validation.error);
                    }
                });
            }
            this.snackbar = this.errors.length > 0;
            if (this.errors.length === 0) {
                this.storeApplicants({
                    application_id: this.currentApplication.id,
                    applicants: this.applicants,
                });
            }
        },
        continueApplication() {
            if (this.step === 1) {
                this.handleApplicationSubmit();
            }
            if (this.step === 2) {
                this.handleApplicantsSubmit();
            }
        },
    },
};
</script>
<style>
.v-stepper__wrapper {
    transition: none !important;
}
</style>
<template>
    <div v-if="currentApplicants && currentApplicants.length">
        <psi-form-layout-section
            title="Confirm Application"
            icon="mdi-clipboard-check-multiple"
            hide-actions
            btn-label=""
            btn-icon=""
        >
            <psi-detail-card
                icon="mdi-clipboard-file"
                :items="applicationDetails"
                headingTitle="Application Details"
            ></psi-detail-card>
            <psi-detail-card
                class="mt-4"
                icon="mdi-account-box-multiple-outline"
                :items="applicantDetails"
                headingTitle="Applicants"
            ></psi-detail-card>
            <v-row class="mt-4">
                <v-col cols="4">
                    <v-hover v-slot="{ hover }">
                        <v-card
                            :to="{ name: 'Uploads Applications' }"
                            :elevation="hover ? 16 : 1"
                        >
                            <v-card-text>
                                <div class="d-flex align-center mx-auto">
                                    <v-avatar
                                        size="80"
                                        color="primary"
                                        class="mr-4"
                                    >
                                        <v-icon size="55" class="white--text"
                                            >mdi-clipboard-text-multiple</v-icon
                                        >
                                    </v-avatar>
                                    <h3>Applications History</h3>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="4">
                    <v-hover v-slot="{ hover }">
                        <v-card
                            link
                            @click.stop="$emit('cancel')"
                            :elevation="hover ? 16 : 1"
                        >
                            <v-card-text>
                                <div class="d-flex align-center mx-auto">
                                    <v-avatar
                                        size="80"
                                        color="secondary"
                                        class="mr-4"
                                    >
                                        <v-icon size="55" class="white--text"
                                            >mdi-clipboard-plus</v-icon
                                        >
                                    </v-avatar>
                                    <h3>Start Another Application</h3>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="4">
                    <v-hover v-slot="{ hover }">
                        <v-card
                            v-if="
                                currentApplicants.length > 0 &&
                                $_.has(currentApplicants[0], 'meta.params')
                            "
                            :href="
                                buildApplyUrl(currentApplicants[0].meta.params)
                            "
                            :elevation="hover ? 16 : 1"
                        >
                            <v-card-text>
                                <div class="d-flex align-center mx-auto">
                                    <v-avatar
                                        size="80"
                                        color="alternate2"
                                        class="mr-4"
                                    >
                                        <v-icon size="55" class="white--text"
                                            >mdi-clipboard-account</v-icon
                                        >
                                    </v-avatar>
                                    <h3>Enter Applications Data</h3>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </psi-form-layout-section>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ApplicantDisplay from "@components/uploads/mixins/ApplicantDisplay";
export default {
    name: "application-uploaded",
    components: {
        // ListMediaFiles: () => import("../Media/ListMediaFiles"),
    },
    mixins: [ApplicantDisplay],
    props: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters("Uploads", ["currentApplicants", "currentApplication"]),
        applicationDetails() {
            return Object.keys(this.currentApplication).length > 0
                ? this.getApplicationDetails()
                : {};
        },
        applicantDetails() {
            return this.currentApplicants.length > 0
                ? this.getApplicantDetails()
                : {};
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        uploadComplete(data) {
            console.log(data);
        },
        getApplicantDetails() {
            return this.currentApplicants.map((item) => {
                const { icon } = this.applicantDisplay(
                    this.$_.get(item, "type", "Applicant")
                );
                const url = this.$_.get(item, "meta.params")
                    ? this.buildApplyUrl(item.meta.params)
                    : "";
                return {
                    icon,
                    label: this.$_.get(item, "type", ""),
                    text: `<a href="${url}">${this.$_.get(
                        item,
                        "name",
                        ""
                    )} (Order ID #${this.$_.get(
                        item,
                        "order.order_id",
                        ""
                    )})</a>`,
                };
            });
        },

        getApplicationDetails() {
            return [
                {
                    icon: "mdi-domain",
                    label: "Company",
                    text: this.$_.get(
                        this.currentApplication,
                        "meta.company.name"
                    ),
                },
                {
                    icon: "mdi-home-city",
                    label: "Property",
                    text: this.$_.get(
                        this.currentApplication,
                        "meta.property.name"
                    ),
                },
                {
                    icon: "mdi-currency-usd",
                    label: "Rent",
                    text: this.$dollarFormat(
                        this.$_.get(this.currentApplication, "meta.rent")
                    ),
                },
                {
                    icon: "mdi-door-closed",
                    label: "Unit",
                    text: this.$_.get(this.currentApplication, "meta.unit"),
                },
                {
                    icon: "mdi-import",
                    label: "Source",
                    text: this.$_.get(this.currentApplication, "source"),
                },
                {
                    icon: "mdi-barcode",
                    label: "Code",
                    text: this.$_.get(this.currentApplication, "code"),
                },
            ];
        },
    },
};
</script>

<style scoped>
</style>
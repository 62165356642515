<template>
    <psi-form-layout-section
        title="Application Source"
        icon="mdi-import"
        hide-actions
        btn-label="Save"
        btn-icon="mdi-arrow-right-bold"
    >
        <psi-form-section
            :value="sourceData"
            ref="section"
            name="application-source"
            label=""
            :fields="fields"
            color="secondary"
            @update:product="updateSource"
        ></psi-form-section>
        <div style="height: 52px"></div>
    </psi-form-layout-section>
</template>
<script>
export default {
    name: "select-source",
    components: {},
    props: {
        sources: {
            type: Array,
            required: true,
        },
        source: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            sourceData: {
                source: this.source,
            },
            fields: [
                {
                    type: "select",
                    name: "source",
                    key: "source",
                    label: "Source",
                    icon: this.$config("icons.source"),
                    "item-value": "name",
                    "item-text": "name",
                    items: this.sources,
                    disabled: this.sources.length === 0,
                    required: true,
                },
            ],
        };
    },
    computed: {},
    watch: {
        source: {
            immediate: true,
            handler(value) {
                this.sourceData.source = value;
            },
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        validate() {
            return this.$refs.section.validate();
        },
        updateSource(data) {
            this.sourceData = data;
            this.$emit("update:source", this.sourceData.source);
        },
    },
};
</script>

<style scoped>
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentApplicants && _vm.currentApplicants.length
    ? _c(
        "div",
        [
          _c(
            "psi-form-layout-section",
            {
              attrs: {
                title: "Confirm Application",
                icon: "mdi-clipboard-check-multiple",
                "hide-actions": "",
                "btn-label": "",
                "btn-icon": ""
              }
            },
            [
              _c("psi-detail-card", {
                attrs: {
                  icon: "mdi-clipboard-file",
                  items: _vm.applicationDetails,
                  headingTitle: "Application Details"
                }
              }),
              _c("psi-detail-card", {
                staticClass: "mt-4",
                attrs: {
                  icon: "mdi-account-box-multiple-outline",
                  items: _vm.applicantDetails,
                  headingTitle: "Applicants"
                }
              }),
              _c(
                "v-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var hover = ref.hover
                                return [
                                  _c(
                                    "v-card",
                                    {
                                      attrs: {
                                        to: { name: "Uploads Applications" },
                                        elevation: hover ? 16 : 1
                                      }
                                    },
                                    [
                                      _c("v-card-text", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-center mx-auto"
                                          },
                                          [
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mr-4",
                                                attrs: {
                                                  size: "80",
                                                  color: "primary"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "white--text",
                                                    attrs: { size: "55" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-clipboard-text-multiple"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c("h3", [
                                              _vm._v("Applications History")
                                            ])
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3702912392
                        )
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var hover = ref.hover
                                return [
                                  _c(
                                    "v-card",
                                    {
                                      attrs: {
                                        link: "",
                                        elevation: hover ? 16 : 1
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.$emit("cancel")
                                        }
                                      }
                                    },
                                    [
                                      _c("v-card-text", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-center mx-auto"
                                          },
                                          [
                                            _c(
                                              "v-avatar",
                                              {
                                                staticClass: "mr-4",
                                                attrs: {
                                                  size: "80",
                                                  color: "secondary"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "white--text",
                                                    attrs: { size: "55" }
                                                  },
                                                  [_vm._v("mdi-clipboard-plus")]
                                                )
                                              ],
                                              1
                                            ),
                                            _c("h3", [
                                              _vm._v(
                                                "Start Another Application"
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          173181009
                        )
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var hover = ref.hover
                                return [
                                  _vm.currentApplicants.length > 0 &&
                                  _vm.$_.has(
                                    _vm.currentApplicants[0],
                                    "meta.params"
                                  )
                                    ? _c(
                                        "v-card",
                                        {
                                          attrs: {
                                            href: _vm.buildApplyUrl(
                                              _vm.currentApplicants[0].meta
                                                .params
                                            ),
                                            elevation: hover ? 16 : 1
                                          }
                                        },
                                        [
                                          _c("v-card-text", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center mx-auto"
                                              },
                                              [
                                                _c(
                                                  "v-avatar",
                                                  {
                                                    staticClass: "mr-4",
                                                    attrs: {
                                                      size: "80",
                                                      color: "alternate2"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "white--text",
                                                        attrs: { size: "55" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-clipboard-account"
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("h3", [
                                                  _vm._v(
                                                    "Enter Applications Data"
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3765923382
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
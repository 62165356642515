<template>
    <div class="mt-4">
        <psi-form-element label="Combined Application File" required>
            <div class="d-flex">
                <psi-file-upload
                    v-model="media"
                    @afterUpload="afterUpload"
                    ref="file"
                >
                </psi-file-upload>
            </div>
        </psi-form-element>
        <v-progress-linear
            v-if="loading"
            color="primary"
            indeterminate
        ></v-progress-linear>
        <div
            class="d-flex justify-center"
            v-if="extension === 'pdf' && !loading && files.length === 0"
        >
            <v-btn color="primary" @click.stop="dialog = true"
                >Split Pages</v-btn
            >
        </div>
        <list-media-files
            selectable
            :media.sync="files"
            v-if="files.length > 0"
        ></list-media-files>

        <v-dialog v-model="dialog" max-width="800">
            <!-- Adding v-if will ensure the component is mounted each time we open the dialog and won't have to reset text area and validation -->
            <applicant-split-pages
                v-if="dialog"
                @close="dialog = false"
                @split:pages="splitPages"
            ></applicant-split-pages>
        </v-dialog>
    </div>
</template>
<script>
import api from "../../api";

export default {
    name: "psi-application-combined",
    components: {
        ApplicantSplitPages: () => import("./ApplicantSplitPages"),
        PsiFileUpload: () => import("./PsiFileUpload"),
        ListMediaFiles: () => import("./ListMediaFiles"),
    },
    props: {
        applicant: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            applicantData: Object.assign({}, this.applicant),
            dialog: false,
            media: {},
            extension: "",
            filepath: null,
            loading: false,
            files: [],
        };
    },
    computed: {},
    watch: {
        media(media) {
            if (
                media === undefined ||
                media === null ||
                Object.keys(media).length === 0
            ) {
                this.files = [];
            }
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        validate() {
            return this.$refs.file.hasMedia();
        },
        updateApplicant() {
            let applicant = Object.assign({}, this.applicant);
            applicant.media = this.files
                .map((file) => {
                    return {
                        uuid: file.id,
                        custom_properties: {
                            category: file.category,
                            uuid: file.id,
                        },
                        source: file.source,
                        name: file.name,
                        path: file.filePath,
                    };
                })
                .reduce((acc, curr) => {
                    acc[curr.uuid] = curr;
                    return acc;
                }, {});
            this.$emit("update:applicant", applicant);
        },
        async afterUpload(event) {
            if (event.success) {
                // Extension determines how we handle uploaded file either we extract zip or setup to split pages
                this.extension = event[event.uuid].extension;
                // We need the filepath from the source for handling further processing
                this.filepath = new URL(
                    event[event.uuid].original_url
                ).pathname.substring(1);
                console.log(this.filepath);
                // PDF extension we enable the split pages button
                if (this.extension === "zip") {
                    this.extractZip();
                }
            }
        },
        /**
         * Extract contents of the uploaded file to a files array
         *
         * @return void
         */
        async extractZip() {
            this.loading = true;
            const result = await api.extractZip(this.filepath, this.$uuid.v4());
            this.loading = false;
            this.files = result.map((item) => {
                return item;
            });
            this.updateApplicant();
        },
        async splitPages(pages) {
            this.dialog = false;
            this.loading = true;
            console.log(pages);
            const result = await api.extractPdf(this.filepath, pages);
            this.loading = false;
            this.files = result.map((item) => {
                return item;
            });
            this.updateApplicant();
        },
    },
};
</script>

<style scoped>
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c(
        "psi-form-element",
        { attrs: { label: "Combined Application File", required: "" } },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("psi-file-upload", {
                ref: "file",
                on: { afterUpload: _vm.afterUpload },
                model: {
                  value: _vm.media,
                  callback: function($$v) {
                    _vm.media = $$v
                  },
                  expression: "media"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: { color: "primary", indeterminate: "" }
          })
        : _vm._e(),
      _vm.extension === "pdf" && !_vm.loading && _vm.files.length === 0
        ? _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.dialog = true
                    }
                  }
                },
                [_vm._v("Split Pages")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.files.length > 0
        ? _c("list-media-files", {
            attrs: { selectable: "", media: _vm.files },
            on: {
              "update:media": function($event) {
                _vm.files = $event
              }
            }
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm.dialog
            ? _c("applicant-split-pages", {
                on: {
                  close: function($event) {
                    _vm.dialog = false
                  },
                  "split:pages": _vm.splitPages
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }